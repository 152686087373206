import React, { useState, useEffect } from "react";
import { toaster, Notification  } from 'rsuite';
import {ResponsiveLine} from '@nivo/line';
import * as Urls from "../Urls.js";

export default function SensorLineGraph (props) {
   const colours = [
      'rgba(255,0,0, 1)',            // red
      'rgba(255,99,15, 1)',          // orange
      'rgba(224, 255, 6, 1)',        // yellow
      'rgba(60, 179, 113, 1)',       // green
      'rgba(0, 128, 219, 1)',        // light blue
      'rgba(31, 0, 189, 1)',         // dark blue
      'rgba(238, 130, 238, 1)',      // pink
      'rgba(18, 90, 205, 1)',        // purple
   ];

   const [data, SetData] = useState();
   const [visible, SetVisible] = useState(true);
   const [all, SetAll] = useState();
   const [loading, SetLoading] = useState(true);
 
   useEffect(() => {
      console.log("SensorLineGraph propps: ", props);
      let url = Urls.SENSOR_DETAILS + `${ props.id }&days=7`;
      console.log("Fetching sensor details: ", url)
      fetch(url)
      .then((rsp) => {
          if(!rsp.ok) {
              throw new Error('Network response failure fetching sensor details: ' + rsp.statusText);
          } else {
              console.log('SENSOR_DETAILS->RSP: ', rsp.body);
              return rsp.json();
          }
      })
      .then((res) => {
          console.log('SENSOR_DETAILS->RES: ',res);
          var dt = [];
          data.map((item, i) => {
             var day = item.Day;
             var points = item.Points.map((pnt) => {
                return {'x': pnt.Time, 'y': pnt.Value};
             });
             var series = {id: day, index: i, data: points}; //, color: colours[i]};
             dt.push(series);
             return '';
          });
          SetData((dt));
          console.log("Data ", dt);
          SetLoading(false);
      })
      .catch((err) => {
          console.log(err);
          toaster.push(<Notification type="error" header="Error" closable>{err.name + ': '+err.message}</Notification>);
          SetLoading(false);
      })
   }, [])

    const showSelected = (item) => {
       if (visible) {
          SetAll(data);
          var idx = data.findIndex((v) => v.id === item.id)
          if (idx !== -1) {
             var new_data = [data[idx]];
             SetData(new_data);
          }
       }
       else {
          SetData(all);
       }
       SetVisible(!visible);
    }
   
    console.log("height", props.height);

    return (
       // TODO need to find a better way to handle the sizing....
      <div style={{height: props.height, display: "flex"}} id="outer">
         <ResponsiveLine
            data={data}
            colors={colours}
            margin={{top: 30, left: 60, right: 100, bottom: 50}}
            useMesh={true}
            yScale={{
               type: 'linear', 
               // min: props.sensor.YMin, 
               // max: props.sensor.YMax, 
            }}  
            xScale={{
               type: 'time', 
               format: '%H:%M:%S', 
               precision: 'minute' 
            }}
            xFormat="time:%H:%M:%S"
            axisLeft={{
               legend: props.sensorType,
               legendOffset: -50,
               legendPosition: 'middle'
            }}
            axisBottom={{
               format: '%H:%M',
               tickValues: 24, 
               legend: 'Time',
               legendOffset: 40,
               legendPosition: 'middle'
            }}
            legends={[
               {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 95,
                  translateY: 0,
                  itemWidth: 85,
                  itemHeight: 22,
                  symbolSize: 15,
                  onClick: (d) => {
                     showSelected(d);
                  },
                  effects: [
                     {
                        on: 'hover',
                        style: {
                           itemBackground: 'rgba(0,0,0, 0.15)',
                           // itemOpacity: 1
                        }
                     }
                  ]
               }
            ]}
         />
      </div>);
 }
 

   //  const [colours, SetColours] = useState([
   //        'rgba(255,0,0, 1)',            // red
   //        'rgba(255,99,15, 1)',          // orange
   //        'rgba(224, 255, 6, 1)',        // yellow
   //        'rgba(60, 179, 113, 1)',       // green
   //        'rgba(0, 128, 219, 1)',        // light blue
   //        'rgba(31, 0, 189, 1)',         // dark blue
   //        'rgba(238, 130, 238, 1)',      // pink
   //        'rgba(18, 90, 205, 1)',        // purple
   //  ]);
  
    // const all_colours = [...colours];
    // const unselected_colour = 'rgb(230, 230, 230)';
      /*
   const showSelectedAll = (item) => {
      if (visible) {
         var idx = data.findIndex((v) => v.id === item.id)
         if (idx !== -1) {
            var new_col = [...colours];
            var i;
            for (i=0;i<colours.length;i++) {
               if (i !== idx) {
                  new_col[i] = unselected_colour;
               }
            }
            SetColours(new_col);
            console.log('colours:', colours, 'new_col: ', new_col);
         }
      }
      else {
         SetColours(all_colours);
         console.log('all_colours:', all_colours, 'colours:', colours);
      }
      SetVisible(!visible);
   }
 */
 //   console.log("NIVO Data:", data);
