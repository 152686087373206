import React, { useState, useEffect } from 'react';
import {Link} from '@reach/router';
import { Avatar} from 'rsuite';
import IotCard from "./IotCard.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function SensorCard (props) {
    const [colour, SetColour] = useState("#d1d5ff")
    // maybe add a card backgroud colour to Settings

    // map the timestamps in case there are more than one
    let locale = navigator.languages[0];
    let ts = props.sensor.Timestamp.map((v, k) => {
        if (k === 0) {
            return <p>on {new Date(v).toLocaleDateString(locale)} at {new Date(v).toLocaleTimeString(locale)}</p>;
        } else {
            return <span>and {new Date(v).toLocaleDateString(locale)} at {new Date(v).toLocaleTimeString(locale)}</span>;
        }
    });

    console.log("LOCALE: ", navigator.languages);

    // the entire card is wrapped in a Link item that is routed from App
    return (
            <div>
                <Link to={`/sensor-analysis/${props.sensor.SensorId}/${props.sensor.Type}/${props.sensor.Location}`}>
                	<IotCard header={props.sensor.Indicator +" " + props.sensor.Type} width={220} background={colour} height={props.height} key={props.key}>
                        <h6>in the {props.sensor.Location}</h6>
                         <Avatar circle style={{backgroundColor: props.sensor.Colour, margin: 10}}>
                             <FontAwesomeIcon icon={props.sensor.Icon}/>
                        </Avatar>
                         <div style={{fontSize: 32, padding: 10}}>{props.sensor.Value + props.sensor.Suffix}</div>
                         <div style={{fontSize: 16}}>{ts}</div>
                         {/* <div style={{fontSize: 16, padding: 10}}>{props.sensor.Timestamp}</div> */}
                	</IotCard>
                </Link>
            </div>
    	
    );
    // return (
            // <div>
                // <Link to={"/details/" + props.sensor.ID}>
                    // <Panel
                        // bordered shaded expanded
                        // header={props.sensor.Type + " in the " + props.sensor.Location}
                        // bodyFill
                        // style={{display: 'inline-block', width: 240, margin: 10, backgroundColor: colour}}
                    // >
                        // <Panel style={{padding: '10px', backgroundColor: 'white', margin: 5}}>
                            // <Avatar circle style={{backgroundColor: colour}}><Icon icon={icon}/></Avatar>
                            // <div style={{fontSize: 32, padding: 10}}>{props.sensor.Value + " " + props.sensor.Suffix}</div>
                        // </Panel>
                    // </Panel>
                // </Link>
            // </div>
    // )
}
