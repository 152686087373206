import React from 'react';

import { IconButton, SelectPicker } from 'rsuite';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';


export default function OptionPicker (props) {
    console.log("OptionPicket: props: ", props);
    return (
        <div>
            <SelectPicker {...props} searchable={false}/>
            <IconButton icon={<FontAwesomeIcon icon={faPlusCircle}/>} onClick={props.onCreate}  circle/>
        </div>
    );
}
