    import React from 'react';

// this component uses FontAwesome Icons

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';

import { SelectPicker } from 'rsuite';

import Icons from '../icons.json';

library.add(fas);

export default function IconPicker (props) {

    // todo - get a proper list  kak thing is that it needs to align with 'fas' above.  The list below is not complete .  probably via a icons.json or something
    const FaIcons = [{"label":"address-book", "value":"address-book"},{"label":"adjust", "value":"adjust"},{"label":"ad", "value":"ad"}, {"label":"fast-backward", "value":"fast-backward"},{"label":"fast-forward", "value":"fast-forward"},{"label":"fax", "value":"fax"},{"label":"female", "value":"female"},{"label":"fighter-jet", "value":"fighter-jet"},{"label":"file", "value":"file"},{"label":"file-download", "value":"file-download"},{"label":"file-upload", "value":"file-upload"},{"label":"film", "value":"film"},{"label":"filter", "value":"filter"},{"label":"fire", "value":"fire"},{"label":"fire-extinguisher", "value":"fire-extinguisher"}]
    
    const prettifyLabel = ((label) => {
        return label[0].toUpperCase() + label.slice(1).replace(/(-)/g, ' ');
    });

    // renders the icons in the SelectPicker list and in the value
    const renderFaIconItem = (label, it) => {
        // console.log("Render FA Icon", label, " ", it);
        return (
            <span>
                <FontAwesomeIcon icon={it.value}/>   {prettifyLabel(label)}
            </span>
        );
    }

    // console.log("FaIcons: ", FaIcons);
    // console.log("Icons: ", Icons);

    return (
        <div>
            <SelectPicker data={Icons} renderMenuItem={renderFaIconItem} renderValue={renderFaIconItem} style={props.style} onChange={props.onChange} value={props.value}/>
        </div>
    );
}
