import { disable } from 'express/lib/application';
import React, { useEffect, useState } from 'react';
import { toaster, Panel, Loader, Button, Form, SelectPicker, Message, ButtonToolbar } from 'rsuite';
import ColorPicker from 'rsuite-color-picker';
import 'rsuite-color-picker/lib/styles.css'
import IconPicker from './components/IconPicker.js';
import * as Urls from './Urls.js';

export default function Settings (props) {

    return (
        <div>
            Settings
        </div>
    );
}
