const API_URL = "https://nguni.mooo.com/sensor_api/v1";
// const API_URL = "http://localhost:13009/sensor_api/v1";
// const API_URL = "http://localhost:14014/sensor_api/v1";
export const GET_SENSORS = API_URL + "/sensors";
export const MEASURE_SETTINGS = API_URL + "/measure_settings";
export const SENSOR_SETTINGS = API_URL + "/sensor_settings";
export const SENSOR_ANALYSIS = API_URL + '/sensor_data?id=';
export const SENSOR_DETAILS = API_URL + '/sensor_data?id=';
export const SERVER_DETAILS = API_URL + '/server';
export const LATEST = API_URL + '/latest';
export const BOARDS = API_URL + '/boards';
export const BOARD = API_URL + '/board';
export const LOCATIONS = API_URL + "/locations";
export const CMD_POLL = API_URL + "/duration";
