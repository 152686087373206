import React, {useState, useEffect} from 'react';
import { Table, Column, HeaderCell, Cell} from 'rsuite-table';
import { Pagination, toaster, Notification  } from 'rsuite';
import * as Urls from "../Urls.js";


export default function SensorTable (props) {
    const [data, SetData] = useState();
    const [sortCol, SetSortCol] = useState('Date');
    const [sortType, SetSortType] = useState('desc');
    const [loading, SetLoading] = useState(true);
    const [page, SetPage] = useState(1);
    const [limit, SetLimit] = useState(10);

 
    const handleSortColumn = (sc, st) => {
        console.log("Sort column: ", sc, "  Sort Type: ", st);
        SetSortCol(sc);
        SetSortType(st);
        // sort data
        SetData(SortData(data));
    }

    const SortData = (d) => {
        return d.sort((x,y) => {
            let a = x[sortCol];
            let b = y[sortCol];
            if (sortCol === 'Date') {
                a = new Date(x[sortCol]).getTime();
                b = new Date(y[sortCol]).getTime();
            }
            if (sortType === 'asc') {
                if (a > b) return -1;
                if (a < b) return 1
                return 0;
            } else {
                if (a > b) return 1
                if (a < b) return -1
                return 0;
            }
        });
    }

    useEffect(() => {
        console.log("Height of table: ", props.height)
        // this is open to XSS attack...
        let url = Urls.SENSOR_DETAILS + `${ props.id }&page=${ page }&limit=10&days=21`;
        console.log("Fetching sensor details: ", url)
        fetch(url)
        .then((rsp) => {
            if(!rsp.ok) {
                throw new Error('Network response failure fetching sensor details: ' + rsp.statusText);
            } else {
                console.log('SENSOR_DETAILS->RSP: ', rsp.body);
                return rsp.json();
            }
        })
        .then((res) => {
            console.log('SENSOR_DETAILS->RES: ',res);
            var dt = []
            res.map((day) => {
               day.Points.map((item, i) => {
                  dt.push({"SensorType": item.SensorType, 'Date': day.Day, 'Time': item.Time, 'Value': item.Value});
                  return '';
               });
               return '';
            });
            SetData(SortData(dt));
            SetLoading(false);
        })
        .catch((err) => {
            console.log(err);
            toaster.push(<Notification type="error" header="Error" closable>{err.name + ': '+err.message}</Notification>);
            SetLoading(false);
        })
    },[page]);
 
    return (
        <div>
            <Table 
                data={data} 
                height={props.height}
                autoHeight
                onSortColumn={handleSortColumn} 
                sortColumn={sortCol} 
                sortType={sortType}
                loading={loading}
            >
                <Column sortable width={200} align='left'>
                    <HeaderCell>Type</HeaderCell>
                    <Cell dataKey="SensorType"/>
                </Column>
                <Column sortable flexGrow={2}>
                    <HeaderCell>Date</HeaderCell>
                    <Cell dataKey="Date"/>
                </Column>
                <Column sortable flexGrow={2}>
                    <HeaderCell>Time</HeaderCell>
                    <Cell dataKey="Time"/>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>Value</HeaderCell>
                    <Cell dataKey="Value"/>
                </Column>
            </Table>
            <div style={{padding: 10}}>
                <Pagination 
                    prev next size="md"
                    limitOptions={[10, 20]} 
                    limit={limit}
                    activePage={page} 
                    total={1000}
                    onChangePage={SetPage}
                    onChangeLimit={SetLimit}
                    maxButtons={10}
                />
            </div>
        </div>
    );
}
 
