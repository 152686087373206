import React, { useEffect, useState } from 'react';
import { toaster, Panel, Loader, Button, Form, SelectPicker, Message, ButtonToolbar } from 'rsuite';
import ColorPicker from 'rsuite-color-picker';
import 'rsuite-color-picker/lib/styles.css'
import IconPicker from './components/IconPicker.js';
import * as Urls from './Urls.js';

export default function MeasureSettings (props) {
    const [types, SetTypes] = useState();
    const [loading, SetLoading] = useState(true);
    const [disabled, SetDisabled] = useState(true);

    const [type_code, SetTypeCode] = useState();
    const [type_name, SetTypeName] = useState();
    const [description, SetDescription] = useState();
    const [colour, SetColour] = useState('#fff')
    const [suffix, SetSuffix] = useState();
    const [min, SetMin] = useState(0);
    const [max, SetMax] = useState(0);
    const [icon, SetIcon] = useState();
    
    const [original_type_code, SetOriginalTypeCode] = useState();
    const [original_type_name, SetOriginalTypeName] = useState();
    const [original_description, SetOriginalDescription] = useState();
    const [original_colour, SetOriginalColour] = useState('#fff');
    const [original_suffix, SetOriginalSuffix] = useState();
    const [original_min, SetOriginalMin] = useState();
    const [original_max, SetOriginalMax] = useState();
    const [original_icon, SetOriginalIcon] = useState();

    useEffect(() => {
        console.log("fetch from ", Urls.MEASURE_SETTINGS)
        fetch(Urls.MEASURE_SETTINGS)
        .then((rsp) => {
            if (rsp.ok) {
                console.log("SETTINGS rsp ", rsp);
                return rsp.json();
            } else {
                console.log("Error fetching measure types:", rsp);
                throw new Error("Network response failure fetching measure types: " + rsp.statusText);
            }
        })
        .then((js) => {
            console.log("Types JSON: ", js);
            SetTypeCode(js[0].Type);
            SetTypes(js.map((item) => {
                return {"label": item.Name, "value": item.Type};   // format required by the input picker
            }));
        })
        .catch(err => {
            toaster.push(<Message type="error" closable>{err.name + ': '+err.message}</Message>);
            console.log(err);
        });
        SetLoading(false)
    }, []);

    const typeSelected = (v) => {
        console.log("Type selected: ", v);
        SetLoading(true);
        fetch(Urls.MEASURE_SETTINGS + '?type=' + v)
        .then((rsp) => {console.log("RSP: ", rsp); return rsp.json()})
        .then((json) => {
            console.log("JSON: ", json);
            SetTypeCode(json["TypeCode"]);
            SetTypeName(json['TypeName']);
            SetDescription(json['Description']);
            SetMin(Number(json['YMin']));
            SetMax(Number(json['YMax']));
            SetColour(json['Colour'])
            SetSuffix(json['Suffix']);
            SetIcon(json['Icon']);
            // set original values too
            SetOriginalTypeCode(type_code);
            SetOriginalTypeName(type_name);
            SetOriginalDescription(description);
            SetOriginalMin(min);
            SetOriginalMax(max);
            SetOriginalColour(colour);
            SetOriginalSuffix(suffix);
            SetOriginalIcon(icon);

            SetDisabled(false); 
            SetLoading(false)})
        .catch((err) => {
            toaster.push(<Message type="error" closable>{err.name + ': '+err.message}</Message>);
            console.log(err); 
            SetDisabled(false); 
            SetLoading(false)
        });
    }

    const typeCleaned = () => {
        console.log("Type cleaned")
    }

    const handleSave = () => {
        SetTypeCode();
        SetDisabled(true);
        // create the body
        let json = {"TypeCode": type_code, "TypeName": type_name, "Description": description,  "YMax": max, "YMin": min, "Colour": colour, "Suffix": suffix, "Icon": icon};
        let b = JSON.stringify(json);

        console.log("BODY: ", b);
        fetch(Urls.MEASURE_SETTINGS, {
            method: 'POST',
            body: b
        })
        .then((rsp) => {
            console.log(rsp);
            if (rsp.ok) {
                toaster.push(<Message showIcon type="success" closable>Settings saved</Message>);   
            } else {
                const message = 
					(<Message showIcon type="error" header="Error" closable>
						Updating measure settings for {type_code} failed with status {rsp.statusText}
					</Message>);
				toaster.push(message);
            }
        });
    }

    const handleReset = () => {
        SetTypeCode();
        SetDisabled(true);
        SetTypeCode(original_type_code);
        SetTypeName(original_type_name);
        SetDescription(original_description);
        SetColour(original_colour);
        SetMin(original_min);
        SetMax(original_max);
        SetSuffix(original_suffix);
        SetIcon(original_icon);
    }

    const onChangeColour = (fv) => {
        console.log("onChangeColour: ", fv)
        SetColour(fv['hex']);
    }

    const onChange = (fv, e) => {
        console.log("Change ",e.target.name);
        switch (e.target.name) {
            case 'Description': SetDescription(fv);break;
            case 'Suffix': SetSuffix(fv); break;
            default: toaster.push(<Message type="error" closable>{e.target.name} is not a valid element</Message>);
        }
    }

    const onChangeNumber = (fv, e) => {
        console.log("ChangeNumber: ",e.target.name);
        switch (e.target.name) {
            case 'Min': SetMin(Number(fv)); break;
            case 'Max': SetMax(Number(fv)); break;
            default: toaster.push(<Message type="error" closable>{e.target.name} is not a valid element</Message>);
        }
    }

    const onChangeIcon = (fv, e) => {
        console.log("Icon: ", fv, " ", e);
        SetIcon (fv);
    }

    return (
        <div>
        { loading ? <Loader center content="Loading settings..." size="lg" /> : <div/>}
        <Panel style={{margin: 10, padding: 20, overflow: 'visible'}} bodyFill header="Settings for measures">
                <Form layout='horizontal'>
                    <Form.Group>
                        <Form.ControlLabel>Measure type:</Form.ControlLabel>
                        <Form.Control name='TypeCode' accepter={SelectPicker} data={types} onChange={typeSelected} onClean={typeCleaned} style={{width: 200}}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Description:</Form.ControlLabel>
                        <Form.Control name='Description' onChange={onChange} style={{width: 400}} value={description} readOnly={disabled}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Maximum Value</Form.ControlLabel>
                        <Form.Control name='Max' type='number' onChange={onChangeNumber} readOnly={disabled} value={max}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Minimum Value</Form.ControlLabel>
                        <Form.Control name='Min' type='number' readOnly={disabled} onChange={onChangeNumber} value={min}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Metric Colour</Form.ControlLabel>
                        <Form.Control 
                            name="Colour" 
                            accepter={ColorPicker} 
                            defaultValue="#fff" 
                            onChange={onChangeColour} 
                            readOnly={disabled}
                            disabled={disabled}
                            value={colour}/>
                        <Form.HelpText tooltip style={{marginLeft: 10}}>Select colour for metric boxes</Form.HelpText>
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Suffix</Form.ControlLabel>
                        <Form.Control name="Suffix" type="text" readOnly={disabled} onChange={onChange} value={suffix}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>Icon</Form.ControlLabel>
                         <Form.Control 
                            name="Icon" 
                            size="lg"
                            accepter={IconPicker} 
                            readOnly={disabled}
                            onChange={onChangeIcon}
                            style={{width: 200}}
                            value={icon}
                        />
                    </Form.Group>
                </Form>
                <ButtonToolbar style={{padding: 10}}>
                    <Button appearance='primary' onClick={handleSave} style={{width: 150}}>Save</Button>
                    <Button appearance='default' onClick={handleReset} style={{width: 150}}>Reset</Button>
                </ButtonToolbar>
            </Panel>
    </div>
    );
}
