import React from 'react';

import {Panel} from "rsuite";

/*-----------------------------------------------------------------------------
The IotCard component is a generic card for displaying board, sensor, controller 
or other elements.
There is an outer Panel for the outline and an inner Panel to house content.
-------------------------------------------------------------------------------*/
export default function IotCard (props) {
	return (
		<Panel bordered shaded expanded bodyFill 
			header={<h6>{props.header}</h6>}
			style={{display: 'inline-block', width: props.width, margin: 10, backgroundColor: props.background, fontSize: 18}}
		>		
			<Panel style={{padding: '10px', backgroundColor: 'white', margin: 5, color: 'black', height: props.height}}>  
				{props.children}
			</Panel>
		</Panel>
	);
}
