import React, {useState} from 'react';
// import useState from 'react';
import './App.css';

import 'rsuite/dist/rsuite.min.css';

import { Container, Navbar, Nav, Header, CustomProvider} from 'rsuite';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTachometerAlt, faServer, faMicrochip, faHeartbeat, faCog, faWeight, faWaveSquare} from '@fortawesome/free-solid-svg-icons'

import { Link, Router } from '@reach/router';
import ServerDetails from './components/ServerDetails';
import Boards from './components/Boards';
import Sensors from './components/Sensors';
import Dashboard from './Dashboard';
// import TableDashboard from './TableDashboard';
import BoardDetails from './components/BoardDetails';
import SensorAnalysis from './components/SensorAnalysis';
import MeasureSettings from './MeasureSettings';
import Settings from './Settings';

export default function App() {
  const [active, SetActive] = useState();

  const select = (eventKey) => {
    // alert("selection made from: " + active + ' to ' + ek);
    console.log("SELECT ", active, ' -> ', eventKey);
    SetActive(eventKey);
  }

  return (
    <CustomProvider theme='light'>
    <div className="App">
        <Container>
          <Header>
            <Navbar appearance='inverse'>
              <Navbar.Brand as='div'>
                  RS Sensor Server
              </Navbar.Brand>
              <Nav activeKey={active} onSelect={select}>
                <Nav.Item href="/" icon={<FontAwesomeIcon icon={faTachometerAlt}/>} eventKey='dashboard'>Dashboard</Nav.Item>
                <Nav.Item href="/server" icon={<FontAwesomeIcon icon={faServer}/>} eventKey='server'>Server</Nav.Item> 
                <Nav.Item href="/boards" icon={<FontAwesomeIcon icon={faMicrochip}/>} eventKey='boards'>Boards</Nav.Item>
                <Nav.Item href="sensors" icon={<FontAwesomeIcon icon={faHeartbeat}/>} eventKey='sensors'> Sensors</Nav.Item>


                {/* <Nav.Item href="/" icon={<FontAwesomeIcon icon={faTachometerAlt}/>}> Dashboard</Nav.Item>
                <Nav.Item href="/server" icon={<FontAwesomeIcon icon={faServer}/>}> Server</Nav.Item> 
                <Nav.Item href="/boards" icon={<FontAwesomeIcon icon={faMicrochip}/>}> Boards</Nav.Item>
                <Nav.Item disabled href="/sensors" icon={<FontAwesomeIcon icon={faHeartbeat}/>}>  Sensors</Nav.Item> */}

  {/*                    <Nav.Item href="/" onClick={() => SetActiveLink("Dashboard")} active={activeLink === 'Dashboard'} icon={<Icon icon='frame'/>}>Dashboard</Nav.Item>
                  <Nav.Item href="/server" onClick={()=> {console.log("setting server active");SetActiveLink('Server')}} active={activeLink === "Server"} icon={<Icon icon="server"/>}>Server</Nav.Item> 
                  <Nav.Item href="/boards" onClick={() => SetActiveLink("Boards")} active={activeLink === "Boards"} icon={<Icon icon="microchip"/>}>Boards</Nav.Item>
                  <Nav.Item  href="/list" onClick={() => SetActiveLink("Sensors")} active={activeLink === 'Sensors'} icon={<Icon icon='heartbeat'/>}>Sensors</Nav.Item>
  */}                    
              </Nav>
              <Nav pullRight>
                <Nav.Menu appearance="subtle" icon={<FontAwesomeIcon icon={faCog} href="/sensor_settings"/>} title="Settings">
                  <Nav.Item icon={<FontAwesomeIcon icon={faWeight}/>}>
                    <Link to="/measure_settings" style={{textDecoration: 'none'}}>  Measures</Link>
                  </Nav.Item> 
                  <Nav.Item icon={<FontAwesomeIcon icon={faWaveSquare}/>}>
                    <Link to="sensor_settings" style={{textDecoration: 'none'}}>  Sensors</Link>
                  </Nav.Item>
                </Nav.Menu> 
              </Nav>
            </Navbar>
          </Header>
        </Container>
          <Router>
            <Dashboard path="/" default/>
            <ServerDetails path="/server"/>
            <Boards path='/boards'/>
            <BoardDetails path="/board/:boardId"/>
            <Sensors path='/sensors'/> 
            <SensorAnalysis path='/sensor-analysis/:sensorId/:sensorType/:sensorLocation'/>
            <MeasureSettings path="measure_settings"/>
            <Settings path="sensor_settings"/>
          </Router>
    </div>
    </CustomProvider>
  );
}
