import React, { useEffect, useState } from 'react';
import {toaster, Notification, Panel, Loader } from 'rsuite';
import { Table, Column, HeaderCell, Cell } from 'rsuite-table';

// import SensorCard from './SensorCard';
import * as Urls from '../Urls.js';
// import SensorDetails from './SensorDetails';
import { useNavigate } from '@reach/router';


export default function Sensors (props) {
    const[data, SetData] = useState([]);
    const [loading, SetLoading] = useState(true);
    // const [detailsVisible, SetDetailsVisible] = useState(false);
    // const [sensor, SetSensor] = useState({});

    const navigate = useNavigate();
  
    console.log("SENSOR URL ", Urls.GET_SENSORS);

    useEffect(() => {  
        fetch(Urls.GET_SENSORS)
        .then((rsp) => {
        	if(!rsp.ok) {
        		throw new Error("Network response failure: " + rsp.statusText);
        	}
        	console.log('GET_SENSORS->RSP: ', rsp);
        	return rsp.json();
        })
        .then((res) => {console.log('GET_SENSORS->RES:',res);SetData(res);console.log('GET_SENSORS->Data: ', data)})
        .catch(err => {
            toaster.push(<Notification type="error" header="Error" closable>{err.name + ': ' + err.message}</Notification>);
            console.log(err)
        });
       SetLoading(false);
    },[]);

	const handleRowClick = (rd) => {
        console.log("Row: ", rd);
        navigate(`/sensor-analysis/${rd.ID}/${rd.Description}/${rd.Location}`)
	}

    if (loading) {
        return <Loader center content="Loading sensors..." size="lg"/> 
    } else {
            return (
                <Panel bordered shaded style={{margin: 10}} bodyFill>
                    <Table data={data}bordered headerHeight={60} hover rowHeight={60} autoHeight cellBordered onRowClick={handleRowClick}>
                        <Column width={100} align="left" verticalAlign="middle">
                            <HeaderCell>Type</HeaderCell>
                            <Cell dataKey="Type"/>
                        </Column>
                        <Column minWidth={250} align="left" verticalAlign="middle" flexGrow={1}>
                            <HeaderCell>Description</HeaderCell>
                            <Cell dataKey="Description"/>
                        </Column>
                        <Column minWidth={250} align="left" verticalAlign="middle" flexGrow={1}>
                            <HeaderCell>Location</HeaderCell>
                            <Cell dataKey="Location"/>
                        </Column>
                        <Column width={200} align="left" verticalAlign="middle">
                            <HeaderCell>Board MAC</HeaderCell>
                            <Cell dataKey="Mac"/>
                        </Column>
                    </Table>
                </Panel>
            );
    }
}

            // <FlexboxGrid>
                // {data.map((item) => {
                    // return (
                        // <FlexboxGrid.Item key={item.ID}>
                            // <SensorCard sensor={item} key={item.ID}/>
                        // </FlexboxGrid.Item>
                    // );
                // })}
            // </FlexboxGrid>);
