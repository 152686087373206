import React, { useEffect, useState } from 'react';
import {toaster, FlexboxGrid, Message} from 'rsuite';
import SensorCard from './components/SensorCard.js';
import * as Urls from './Urls.js';


export default function Dashboard () {
    const [data, SetData] = useState([])

    useEffect(() => {
        console.log("Fetching measurement statistics from ", Urls.LATEST)
        fetch(Urls.LATEST)
        .then((rsp) => {
        	console.log("received ", rsp); 
        	if (!rsp.ok) {
        		throw new Error('Network response failure: ' + rsp.statusText)
        	};
        	return rsp.json()})
        .then((json) => {console.log(json); SetData(json)})
        .catch((err) => {
            toaster.push(
                <Message type="error" closable>{err.name + ': '+err.message}</Message>
            );
            console.log(err)
        });
    }, []);

    // work out the height based on number of timestamps so that all cards are the same height
    let num_ts = 0;
    data.forEach((v, i, a) => {
        let l = v.Timestamp.length
        num_ts = num_ts > l ? num_ts : l
    })
    let h = 220 + (num_ts * 12)

    return (
            <FlexboxGrid>
                {data.map((item, i) => {
                    return (
                        <SensorCard sensor={item} key={i} height={h}/>
                        // <FlexboxGrid.Item key={i}>
                        //     <Panel header={item.Type} collapsible bordered style={{display: 'inline-block', width: 240, margin: 10, backgroundColor: 'lightblue'}}>
                        //         <Panel>{item.Value} == {item.Timestamp}</Panel>
                        //     </Panel>
                        // </FlexboxGrid.Item>
                    );
                })}
            </FlexboxGrid>
    );
}
