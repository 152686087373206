import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Panel, toaster, Notification } from 'rsuite';
import SensorLineGraph from './SensorLineGraph';
import SensorTable from './SensorTable';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faTable, faChartLine } from '@fortawesome/free-solid-svg-icons';

import * as Urls from "../Urls.js";

export default function SensorAnalysis (props) {
    const [graph, SetGraph] = useState(false);
    const [height, SetHeight] = useState();

    const onSelectTable = () => {
        SetGraph(!graph);
    }

    useEffect(() => {
        // work out the container height to pass to Table and Chart
        SetHeight(window.innerHeight - 150);
        const handleWindowResize = () => SetHeight(window.innerHeight - 150);
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
     },[props.id]);

    console.log("SensorAnalysis props: ", props);
  
    return (
        <Panel bordered shaded style={{margin: 10}} bodyFill 
            header={<SensorAnalysisHeader type={props.sensorType} location={props.sensorLocation} onSelectTable={onSelectTable} graph={graph}/>}>
            <div>
                {graph ?
                    <SensorLineGraph id={props.sensorId} height={height} /> :
                    <SensorTable id={props.sensorId} height={height} />
                }
            </div>
        </Panel>
    );
}

function SensorAnalysisHeader (props) {
    console.log("SensorAnalysis props: ", props);

    return (
		<Navbar>
			<Navbar.Brand>Sensor Details: {props.type} in {props.location}</Navbar.Brand>
            <Nav pullRight>
                <Nav.Item 
                	onSelect={props.onSelectTable} 
                	icon={props.graph ? <FontAwesomeIcon icon={faTable}/> : <FontAwesomeIcon icon={faChartLine}/>}>
                	{props.graph ? '  Table' : '  Chart'}
                </Nav.Item> 
                <Nav.Item href='/sensors' style={{textDecoration: 'none'}} icon={<FontAwesomeIcon icon={faWindowClose}/>}>   Close</Nav.Item> 
            </Nav>
		</Navbar>
	);

}
