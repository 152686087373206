import React, { useState, useEffect } from 'react';
import { useNavigate } from '@reach/router';
import {toaster, Notification, Loader, Table, Panel} from 'rsuite';
// import BoardDetails from './BoardDetails.js';

import * as Urls from '../Urls.js';

const {Column, HeaderCell, Cell} = Table;

export default function Boards () {
    const [boardList, SetBoardList] = useState([]);
    const [loading, SetLoading] = useState(true);

	useEffect(() => {
		console.log("fetching boards");
		fetch(Urls.BOARDS)
		.then((rsp) => {
			if(!rsp.ok) {
				throw new Error('Network response failure" ' + rsp.statusText);
			};
			console.log("got boards data");
			return rsp.json()
		})
		.then((json) => {console.log("JSON boards: ", json);SetBoardList(json); SetLoading(false)})  // TODO modify the board list to include the number of sensors and put in a Column
		.catch((err) => {
			SetLoading(false);
			toaster.push(<Notification type="error" closable>{err.name + ': '+err.message}</Notification>);
			return;
		});
	},[]);

    if (loading) {
        return <Loader center content="Loading Boards" size="lg"/>
    } else {
    return (
   		<TableView boards={boardList}/>
    	);
    }
}

function TableView (props) {
	console.log("props: ", props.boards);
	const navigate = useNavigate();


	const handleRowClick = (rowData) => {
		console.log("navigating now");
		navigate(`/board/${rowData.ID}`);
	}
	
	// const handleClose = () => SetDetailsVisible(false);

	return (
		<div>
			<Panel bordered shaded style={{margin: 10}} bodyFill>
				<Table data={props.boards} bordered headerHeight={60} hover rowHeight={60} autoHeight cellBordered onRowClick={handleRowClick}>
					<Column width={200} align="left" verticalAlign="middle">
						<HeaderCell>Board Type</HeaderCell>
						<Cell dataKey="Type"/>
					</Column>
					<Column minWidth={250} align="left" verticalAlign="middle" flexGrow={1}>
						<HeaderCell>Location</HeaderCell>
						<Cell dataKey="Location"/>
					</Column>
					<Column width={200} align="left" verticalAlign="middle">
						<HeaderCell>MAC Address</HeaderCell>
						<Cell dataKey="Mac"/>
					</Column>
					<Column align="left" verticalAlign="middle">
						<HeaderCell>Chip ID</HeaderCell>
						<Cell dataKey="ChipID"/>
					</Column>
					<Column align="left" verticalAlign="middle">
						<HeaderCell>Free Heap</HeaderCell>
						<Cell dataKey="FreeHeap"/>
					</Column>
					<Column align="left" verticalAlign="middle">
						<HeaderCell>Signal</HeaderCell>
						<Cell dataKey="Signal"/>
					</Column>
					<Column align="left" verticalAlign="middle">
						<HeaderCell>Duration</HeaderCell>
						<Cell dataKey="Duration"/>
					</Column>
					<Column align="left" verticalAlign="middle" minWidth={100} flexGrow={1}>
						<HeaderCell>Version</HeaderCell>
						<Cell dataKey="Version"/>
					</Column>
				</Table>
			</Panel>
		</div>);
}
