import { useEffect, useState } from "react";
import React from "react";
import {Form, Loader } from "rsuite";
import * as Urls from './../Urls';
import IotCard from "./IotCard";


export default function ServerDetails () {
    const [data, setData] = useState([]);
    const [loading, SetLoading] = useState(true);

    useEffect(() => {
        console.log("Fetching ", Urls.SERVER_DETAILS);
        fetch(Urls.SERVER_DETAILS)
        .then((rsp) => rsp.json())
        .then((res) => {setData(Object.entries(res)); SetLoading(false)})
        .catch((err) => {SetLoading(false);console.log(err)})
        console.log("Fetched: ", data);
    },[]);

    if (loading) {
        return <Loader center content="Loading server details" size="lg"/> 
    } else {
        return (<IotCard header='Server Details' background="blue" color="white" width="500">
        <Form layout="horizontal">
            {data.map((item, index) => {
                return (
                <Form.Group key={index}>
                    <Form.ControlLabel style={{width: 100}}>{item[0]}</Form.ControlLabel>
                    <Form.Control placeholder={item[1]} readOnly style={{width: 200}}/>
                </Form.Group>);
            })}
        </Form>
    </IotCard>);
        }
}
    /*
    return (
        <Form layout='horizontal'>
            <FormGroup>
                <ControlLabel>Operating System</ControlLabel>
                <Input name="os" disabled placeholder={() => data.OperatingSystem}/>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Architecture</ControlLabel>
                <Input name='arch' disabled placeholder={() => data.Architecture}/>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Host name</ControlLabel>
                <Input name='hn' disabled placeholder={() => data.Hostname}/>
            </FormGroup>
        </Form>
    );
    */