import React, {useState, useEffect} from 'react';

import { Panel, Form, IconButton, Grid, Row, Col, Table, InputNumber, Loader, toaster, Message, Navbar, Nav} from 'rsuite';
import OptionPicker from './OptionPicker.js';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';

import * as Urls from '../Urls.js';
import { Link, useNavigate } from '@reach/router';

const {Column, HeaderCell, Cell} = Table;


export default function BoardDetails (props) {
	const [duration, SetDuration] = useState(); //props.data.Duration);
	const [locations, SetLocations] = useState([]);
    const [loading, SetLoading] = useState(true);
    const [location, SetLocation] = useState(); //props.data.Location);
    const [board, SetBoard] = useState();

	const navigate = useNavigate();

	const fcMarginBottom = 2;

	console.log("Props ", props);

	useEffect(() => {
		console.log("Fetching locations");
		fetch(Urls.LOCATIONS)
		.then((rsp) => {
			console.log("Locations response: ", rsp);
			if(!rsp.ok){
				toaster.push(<Message type="warning" header="Warning" closable>No locations found</Message>);
				return;
			};
			return rsp.json()
		})
		.then((json) => {
			let locs = json.map((v) => {
				console.log(v);
				let l = {'label': v.Name, 'value': v.Name, 'id': v.Id};
				console.log(l);
				return l;
			});
			console.log(locs);
			SetLocations(locs);
			console.log("Locations: ", locations);
		})
		.catch((err) => {
			console.log(err);
			toaster.push(<Message type="error" header="Error" closable>{err.name + ': '+err.message}</Message>);
		})

		let url = Urls.BOARD + "?board=" + props.boardId;
		fetch(url)
		.then((rsp) => {
			if(!rsp.ok) {
				const message = 
					(<Message type="error" header="Error" closable>
						Could not fetch board with ID {props.boardId}. Status: {rsp.statusText} 
					</Message>);
				toaster.push(message);
				SetLoading(false);
				return;
			}else {
				return rsp.json();
			}
		})
		.then((json) => {
			console.log("JSON:", json);
			SetBoard(json);
			console.log("Board: ", board);
			SetDuration(json.Duration);
			SetLocation(json.Location);
			SetLoading(false);
		})
		.catch((err) => {
			console.log(err);
			toaster.push(<Message type="error" header="Error" closable>{err.name + ': '+err.message}</Message>);
			SetLoading(false);
		})
	},[]);

	const sendCommand = () => {
		let url_parms = "?mac=" + board.Mac + "&duration=" + duration;
		console.log("URL params: ", url_parms)
		fetch(Urls.CMD_POLL + url_parms)
		.then((rsp) => {
			if(!rsp.ok) {
				const message = 
					(<Message type="error" header="Error" closable>
						Command to {board.Mac} failed with status {rsp.statusText}
					</Message>);
				toaster.push(message);
			} else {
				const message = 
					(<Message type="success" header="Success!" closable>
						Command sent successfully to {board.Mac}
					</Message>);
				toaster.push(message);
			}
		})

	}

	const createLocation = () => {
		console.log("new location:");
		// make a modal to capture the new location
		alert("New location");
	}
	
	const newLocation = (value, item) => {
		fetch(Urls.LOCATIONS, {method: 'POST', body: value})
		.then((rsp) => {
			console.log("Response for createLocation ", rsp)
			if (!rsp.ok) {
				const message = 
					(<Message type="error" header="Error" closable>
						Adding location {value} failed with status {rsp.statusText}
					</Message>);
				toaster.push(message);
			} else {
				const message = 
					(<Message type="success" header="Success!" closable>
						Added location {value}
					</Message>);
				toaster.push(message);
			}
		});
	}
	
	const locationChanged = (newVal) => {
		SetLocation(newVal);
		// need to update the board to the new value
		console.log("location changed to ", newVal);
		// find the id for the new name
		let newLoc = locations.find((v) => {return v.value === newVal})
		let u = Urls.BOARD + "?board=" + board.ID + "&location=" + newLoc.id;
		console.log("board update url for new location: ", u);
		fetch(u, {method: 'PATCH'})
		.then((rsp) => {
			console.log("Response for location changed ", rsp)
			if (!rsp.ok) {
				const message = 
					(<Message type="error" header="Error" closable>
						Updating location {newVal} failed with status {rsp.statusText}
					</Message>);
				toaster.push(message);
			} else {
				const message = 
					(<Message type="success" header="Success!" closable>
						Updated location {newVal} for board {board.Mac}
					</Message>);
				toaster.push(message);
			}
		})
	}

	const handleRowClick = (rd) => {
		console.log('link: [', rd, ']');
		navigate(`/sensor-analysis/${rd.ID}/${rd.Type}/${location}`)
	}

	if (loading) {
		return <Loader center content="Loading board details..." size="lg"/> 
	} else {
   		return (	
			<Panel header={<BoardDetailHeader mac={board.Mac}/>} bordered shaded style={{margin: 10}}>
				<Grid fluid>
					<Row gutter={10}>
						<Col sm={8} md={8} lg={8}>
							<Panel bordered style={{backgroundColor: '#f0f0f0'}}>
								<Form layout="horizontal" style={{padding: '2px'}}>
									<Form.Group style={{marginBottom: fcMarginBottom}}>
										<Form.ControlLabel style={{width: 100}}>MAC address:</Form.ControlLabel>
										<Form.Control name="mac" value={board.Mac} readOnly style={{width: 150}}/> 
									</Form.Group>
									<Form.Group style={{marginBottom: fcMarginBottom}}>
										<Form.ControlLabel style={{width: 100}}>Location:</Form.ControlLabel>
										<Form.Control name="location" value={location}
											accepter={OptionPicker}
												size='sm'
												preventOverflow
												style={{width: 150}}
												data={locations}
												// value={location}
												onCreate={createLocation}
												onChange={locationChanged}
										/> 
									</Form.Group>
									<Form.Group style={{marginBottom: fcMarginBottom}}>
										<Form.ControlLabel style={{width: 100}}>Signal:</Form.ControlLabel>
										<Form.Control name="signal" value={board.Signal} readOnly style={{width: 150}}/> 
									</Form.Group>
									<Form.Group style={{marginBottom: fcMarginBottom}}>
										<Form.ControlLabel style={{width: 100}}>Type:</Form.ControlLabel>
										<Form.Control name="type" value={board.Type} style={{width: 150}}/> 
									</Form.Group>
									<Form.Group style={{marginBottom: fcMarginBottom}}>
										<Form.ControlLabel style={{width: 100}}>Chip ID:</Form.ControlLabel>
										<Form.Control name="chipid" value={board.ChipID} style={{width: 150}}/> 
									</Form.Group>
									<Form.Group style={{marginBottom: fcMarginBottom}}>
										<Form.ControlLabel style={{width: 100}}>Free Heap:</Form.ControlLabel>
										<Form.Control name="freeheap" value={board.FreeHeap} style={{width: 150}}/> 
									</Form.Group>
									<Form.Group style={{marginBottom: fcMarginBottom}}>
										<Form.ControlLabel style={{width: 100}}>Poll delay:</Form.ControlLabel>
										<Form.Control name="duration" value={duration} style={{width: 150}}/> 
									</Form.Group>
									<Form.Group style={{marginBottom: fcMarginBottom}}>
										<Form.ControlLabel style={{width: 100}}>Software Version:</Form.ControlLabel>
										<Form.Control name="version" value={board.Version} readOnly style={{width: 150}}/> 
									</Form.Group>
								</Form>
							</Panel>
						</Col>						
						<Col  sm={8} md={8} lg={8}>
							<Panel header="Sensors and Controllers" bordered style={{backgroundColor: '#f0f0f0'}} >
								Sensors and Controllers
								<Table data={board.Sensors} onRowClick={handleRowClick}>
										<Column>
											<HeaderCell>Sensor Type</HeaderCell>
											<Cell dataKey='TypeCode'/>
										</Column>
										<Column flexGrow={1}>
											<HeaderCell>Description</HeaderCell>
											<Cell dataKey='Type'/>
										</Column>
								</Table>
							</Panel>
						</Col>
						<Col sm={8} md={8} lg={8}>
							<Panel header="Commands" bordered style={{backgroundColor: '#f0f0f0'}}>
								<Form layout="horizontal" style={{marginBottom: 20}}>
									<Form.Group>
										<Form.ControlLabel style={{width: 100}}>Poll duration:</Form.ControlLabel>
										<Form.Control name="poll" 
											style={{width: 200}}
											accepter={InputNumber} 
												min={10} step={10} 
												value={duration} 
												postfix="Seconds" 
												onChange={SetDuration}/>
									</Form.Group>
								</Form>
								<IconButton 
									appearance="primary" color="cyan"
									icon={<FontAwesomeIcon icon={faPaperPlane}/>} 
									onClick={sendCommand}
								>
									  &nbsp;&nbsp;Send Command
								</IconButton>
							</Panel>
						</Col>
					</Row>	
				</Grid>
			</Panel>
		);
   }
}

function BoardDetailHeader (props) {
	return (
		<Navbar>
			<Navbar.Brand>{'Board Details for MAC: ' + props.mac}</Navbar.Brand>
			<Nav pullRight>
				<Nav.Item><Link to="/boards" style={{textDecoration: 'none'}}>Back</Link></Nav.Item>
			</Nav>
		</Navbar>
	);
}